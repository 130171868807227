import { createGlobalStyle } from 'styled-components'
import MontserratBold from './Montserrat-Bold.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratBold}) format('ttf');
    font-weight: 700;
    font-style: bold;
  }
`