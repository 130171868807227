import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "./Grid"
import { TypographySmallTitle, TypographyParagraph } from "./Typography"

const FooterBackground = styled.div`
  background-color: #f7f7f7;
`

const ColOne = styled(Col)`
  min-width: 300px;
  display: flex;
  flex-direction: column;
`

const ColTwo = styled(Col)`
  min-width: 400px;
  @media (max-width: 800px) {
    min-width: 0;
  }
`

const Link = styled.a``

const PlayStore = () => (
  <a href="https://play.google.com/store/apps/details?id=com.test.dieselbank&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <img
      alt="Disponível no Google Play"
      src="https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png"
      width="150px"
      style={{ marginLeft: "-10px" }}
    />
  </a>
)

const AppStore = () => (
  <a href="https://apps.apple.com/br/app/dieselbank-para-caminhoneiros/id1516013322">
    <img
      alt="Disponível no Google Play"
      src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
      width="130px"
    />
  </a>
)

const Footer = () => (
  <FooterBackground>
    <Container>
      <Row>
        <ColOne>
          <TypographySmallTitle gray>DieselBank</TypographySmallTitle>
          <Link href="https://drive.google.com/drive/u/0/folders/1eVMJDKW5UexTxy-0x_D0-sgRcEIJG8ek">
            <TypographySmallTitle black>
              Política de Privacidade
            </TypographySmallTitle>
          </Link>
          <Link href="https://drive.google.com/drive/u/0/folders/1eVMJDKW5UexTxy-0x_D0-sgRcEIJG8ek">
            <TypographySmallTitle black>Termos de Uso</TypographySmallTitle>
          </Link>
          <TypographySmallTitle black>
            Diesel Bank Inst. De Pag. LTDA
          </TypographySmallTitle>
          <TypographySmallTitle black>
            CNPJ: 36.231.001/0001-94
          </TypographySmallTitle>
        </ColOne>
        <ColOne>
          <TypographySmallTitle gray>Fale com a gente</TypographySmallTitle>
          <Link href="https://wa.me/558008871654">
            <TypographySmallTitle black>Whatsapp</TypographySmallTitle>
          </Link>
          <Link href="https://wa.me/558008871654">
            <TypographySmallTitle black>
              atendimento@dieselbank.com
            </TypographySmallTitle>
          </Link>
          <TypographySmallTitle black>
            SAC 24h/7 - 0800 887 1654
          </TypographySmallTitle>
          <TypographyParagraph black>
            Se já passou por outros canais e ainda precisa de ajuda com o seu
            cartão, entre em contato com{" "}
            <span>
              <b>0800 701 0412 - Ouvidoria cartão.</b>
            </span>
          </TypographyParagraph>
        </ColOne>
        <ColOne>
          <TypographySmallTitle gray>Baixe o Aplicativo</TypographySmallTitle>
          <PlayStore />
          <AppStore />
        </ColOne>
      </Row>
      <Row>
        <ColTwo>
          <TypographyParagraph black>
            Nós nos comprometemos a entregar um serviço justo, descomplicado e
            transparente para todas as famílias caminhoneiras que carregam o
            progresso e a mudança através do Brasil.
          </TypographyParagraph>
        </ColTwo>
        <ColTwo>
          <TypographyParagraph black>
            O DieselBank nasceu para que você, motorista de caminhão, possa
            resolver a sua vida pelo celular sem precisar sair de casa - ou do
            caminhão -, deixando para trás a burocracia e as taxas abusivas dos
            cartões de frete dos bancos tradicionais.
          </TypographyParagraph>
        </ColTwo>
      </Row>
    </Container>
  </FooterBackground>
)

export default Footer
