import styled, { css } from 'styled-components';

interface TypographyProps {
  black?: boolean;
  gray?: boolean;
}

export const TypographyLarge = styled.h1<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  font-size: 56px;
  color: #fff;
  ${(props) => props.black && css`
    color: #01192B;
  `}
  ${(props) => props.gray && css`
    color: #586C82;
  `}
  @media(max-width: 800px){
    font-size: 24px;
  }
`;

export const TypographyMedium = styled.h3<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  color: #fff;
  ${(props) => props.black && css`
    color: #01192B;
  `}
  ${(props) => props.gray && css`
    color: #586C82;
  `}
  @media(max-width: 800px){
    font-size: 16px;
  }
`;

export const TypographySmallTitle = styled.h4<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  ${(props) => props.black && css`
    color: #01192B;
  `}
  ${(props) => props.gray && css`
    color: #586C82;
  `}
`;

export const TypographyParagraph = styled.p<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #fff;
  ${(props) => props.black && css`
    color: #01192B;
  `}
  ${(props) => props.gray && css`
    color: #586C82;
  `}
`;

export const TypographyCaption = styled.h4<TypographyProps>`
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  ${(props) => props.black && css`
    color: #01192B;
  `}
  ${(props) => props.gray && css`
    color: #586C82;
  `}
`;
