import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  padding-bottom: 64px;
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 64px;
  & > {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 64px;
  }
  @media(max-width: 800px){
    flex-direction: column;
  }
`;
export const Col = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  flex: 1 0;
`;
