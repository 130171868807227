import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { WindowLocation } from "@reach/router"
import logo from "../images/dieselbank-logo.svg"
import menu from "../icons/bars-solid.svg"
import NavbarItem from "./NavbarItem"

const StyledNavbar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  position: fixed;
  z-index: 1;
  top: 0;
`

const SectionAligner = styled.div`
  width: 100%;
  height: 60px;
`

const LeftNavbarContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`

const RightNavbarContainer = styled.div`
  display: flex;
  align-items: center;
`

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 32px;
  margin-left: 32px;
`

const Logo = styled.img`
  height: 32px;
  margin: 0 0 -4px 0;
`

const Menu = styled.img`
  display: none;
  height: 20px;
  margin: 0;
  cursor: pointer;
  @media (max-width: 800px) {
    display: unset;
  }
`

const Collapse = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: unset;
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: #f7f7f7;
  }
`

const Scrim = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: unset;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const NavbarItemWrapper = styled.div`
  display: flex;
  > * {
    margin: 0;
    padding: 24px 32px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

interface LocationProps {
  location: WindowLocation<WindowLocation["state"]>
}

const ItemList: React.FC<LocationProps> = ({ location }) => (
  <NavbarItemWrapper>
    <>
      <NavbarItem to="/" primary selected={location.pathname === "/"}>
        Você
      </NavbarItem>

      <NavbarItem
        to="/postos"
        primary
        selected={location.pathname === "/postos"}
      >
        Postos
      </NavbarItem>

      <NavbarItem to="https://postopix.com.br/" primary>
        PostoPix
      </NavbarItem>

      <NavbarItem to="/salas" primary selected={location.pathname === "/salas"}>
        Salas
      </NavbarItem>

      <NavbarItem
        to="/dieselbank"
        primary
        selected={location.pathname === "/dieselbank"}
      >
        Sobre nós
      </NavbarItem>
    </>
  </NavbarItemWrapper>
)

const ItemListContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`

const Navbar: React.FC<LocationProps> = ({ location }) => {
  const [open, setOpen] = useState<Boolean>(false)

  return (
    <>
      <SectionAligner />
      <StyledNavbar>
        <NavbarContainer>
          <RightNavbarContainer>
            <Link to="/">
              <Logo src={logo} />
            </Link>
            <ItemListContainer>
              <ItemList location={location} />
            </ItemListContainer>
          </RightNavbarContainer>
          <LeftNavbarContainer>
            <NavbarItem to="https://posto.dieselbank.com.br/">
              Sou um posto parceiro
            </NavbarItem>
          </LeftNavbarContainer>
          <Menu src={menu} onClick={() => setOpen(curr => !curr)} />
        </NavbarContainer>
      </StyledNavbar>
      {open ? (
        <>
          <Collapse>
            <ItemList location={location} />
          </Collapse>
          <Scrim onClick={() => setOpen(curr => !curr)} />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Navbar
