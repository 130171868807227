import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface NavbarItemProps {
  primary?: boolean,
  selected?: boolean,
  to: string,
  children:ReactNode,
}

interface ItemTextProps {
  primary?: boolean,
  selected?: boolean,
}

const ItemText = styled(Link)<ItemTextProps>`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-left: 64px;
  text-decoration: none;
  color: #9E9E9E;
  &:hover{
    color: #FF5C50;
  }
  ${(props) => props.primary && css`
    color: #01192B;
  `}
  ${(props) => props.selected && css`
    color: #FF5C50;
  `}
`;

const NavbarItem:React.FC<NavbarItemProps> = ({
  primary, selected, to, children,
}) => {
  const isPrimary = primary;
  const isSelected = selected;

  return (
    <ItemText primary={isPrimary} selected={isSelected} to={to}>
      {children}
    </ItemText>
  );
};

export default NavbarItem;
